/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Apr 13 2021
 *  File : Message.jsx
 *******************************************/
 import React from 'react';
 import { bindActionCreators } from 'redux';
 import Link from 'next/link'
 import {useRouter} from 'next/router';
 import {connect} from 'react-redux';
 import PropTypes from 'prop-types';
 import ActionCreators from '../../store/ActionCreators';
 import { CustomButton } from '../../components/commons';
 import PostAuthCompanyMap from '../postAuthCompanyMap';
 import {preAuthPaths} from '../../config/constants/NavigationConstants'
 
 const mapStateToProps = (state) => ({
    impossibleVideo: state.impossibleVideoReducer,
    companyForest: state.companyForestReducer
});

const mapDispatchTopProps = (dispatch) => bindActionCreators(ActionCreators, dispatch);

const connector = connect(mapStateToProps, mapDispatchTopProps);
 
 const DynamicPage = ({pageName, ...props}) => {

    const {
        impossibleVideo,
        companyForest
    } = props;
    const history = useRouter();
    const {pathname, query} = history;
 
     let content = null;
     
     if(!!impossibleVideo.isValid) {
        switch(impossibleVideo.loadingStatus) {
            case 'success':
                content = (
                    <>
                        <video 
                        className="w-100" 
                        autoPlay 
                        loop 
                        style={{ width: '', height: '' }} 
                        poster={"../images/impossible-thumbnail.png"} 
                        controls
                        controlslist="nodownload">
                            <source src={`${impossibleVideo.videoUrl}`} type="video/mp4"/>
                        </video>
                        <Link href={`${impossibleVideo.videoUrl}`} target='_blank' download>
                            <CustomButton name="DOWNLOAD VIDEO" />
                        </Link>
                    </>
                );
                break;
            case 'loading':
            content = (
                <>
                    <div className="loader-outer">
                        <div className="impossible-loader"></div>
                    </div>
                </>
            );
            break;
            
            default:
                content = (
                    <>
                    <div className="cmn-head">
                        <h2>Oops! This page does not exist</h2> 
                    </div>                
                    </>
                );
                break;
        }
    }
    else {


        switch(companyForest.loadingStatus) {

            case 'success':

                content = <PostAuthCompanyMap {...props} />;

                break;

            case 'loading':

            content = (

                <>

                    <div className="loader-outer">

                        <div className="impossible-loader"></div>

                    </div>

                </>

            );

            break;

            

            default:

                history.push(preAuthPaths.FOREST);

                break;

        }

    }
     return(
         <>
             {/* <CustomHeader /> */}
             <section className="banner-sec video-wrapper map-banner">
                 <div className="container-wrap">
                     <div className="video-outer">
                         {/* <div className="cmn-head"> */}
                             {content}
                         {/* </div> */}
                     </div>
                 </div>
             </section>
         </>
     )
 };

 DynamicPage.getInitialProps = async (ctx) => {
    const {query} = ctx;
    const {
        pageName
    } = query;

    return {
        pageName,
    }

}

 DynamicPage.defaultProps = {
    impossibleVideo: {},
    companyForest: {},
}

DynamicPage.propTypes = {
    impossibleVideo: PropTypes.instanceOf(Object),
    companyForest: PropTypes.instanceOf(Object)
}
 
 export default connector(DynamicPage);
