
/******************************************

 *  Author : Suraj Sharma

 *  Created On : Fri Jan 22 2021

 *  File : index.jsx

 *******************************************/

import PostAuthCompanyMap from './PostAuthCompanyMap';


export default PostAuthCompanyMap;